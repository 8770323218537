export default function Error() {
    return (
    <main style={{paddingTop: "80px", paddingLeft: "4vw", paddingRight: "2vw"}}>
        <p>We couldn't find the page you were looking for. This is either because:</p>
        <ul>
            <li>There is an error in the URL entered into your web browser. Please check the URL and try again.</li>
            <li>The page you are looking for has been moved or deleted.</li>
        </ul>
        <p>You can return to our homepage by <a style = {{color:"#bd1900"}} href="/">clicking here.</a></p>
      </main>
    );
  }