import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Test from '../test.jpg'

import { useInView } from 'react-intersection-observer';
import { Link, Outlet } from "react-router-dom";
import Photos from '../pages/photos'



import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';




import React, { useState, useEffect } from 'react';


import Pic1 from '../photos/desktop-2.JPG'
import Pic2 from '../photos/gallery2.jpeg'
import Pic3 from '../photos/gallery3.jpg'
import Pic4 from '../photos/gallery4.JPG'
import Pic5 from '../photos/gallery5.JPG'
import Pic6 from '../photos/gallery6.JPG'
import Pic7 from '../photos/gallery7.jpg'
import Pic8 from '../photos/gallery8.jpg'
import Pic9 from '../photos/gallery9.JPG'
import Pic10 from '../photos/gallery10.JPG'
import Pic11 from '../photos/desktop-4.JPG'
import Pic12 from '../photos/gallery12.jpg'
import Pic13 from '../photos/gallery13.JPG'
import Pic14 from '../photos/gallery14.JPG'
import Pic15 from '../photos/gallery15.JPG'
import Pic16 from '../photos/desktop-1.gif'
import Pic17 from '../photos/gallery17.JPG'
import Pic18 from '../photos/gallery18.JPG'
import Pic19 from '../photos/desktop-6.gif'
import Pic20 from '../photos/gallery20.JPG'
import Pic21 from '../photos/gallery21.JPG'
import Pic22 from '../photos/gallery22.JPG'
import Pic23 from '../photos/gallery23.jpg'
import Pic24 from '../photos/gallery24.jpg'
import Pic25 from '../photos/gallery25.JPG'
import Pic26 from '../photos/gallery26.JPG'
import Pic28 from '../photos/gallery28.gif'
import Pic27 from '../photos/gallery27.JPG'
import Pic29 from '../photos/gallery29.jpeg'









export default function Overview() {

    const [open, setOpen] = useState(false);
    const [currval, setCurrval] = useState(Pic1);
    const [srcval, setSRC] = useState(Pic1);


    const allpics = [Pic1, Pic2, Pic3, Pic4, Pic5, Pic8, Pic6, Pic7, Pic9, Pic12, Pic10, Pic23, Pic11, Pic13, Pic14, Pic15, Pic16, Pic22, Pic17, Pic19, Pic18, Pic28, Pic20, Pic24, Pic25, Pic21,Pic26, Pic29, Pic27]



    function goleft() {
      if (currval == 0){
        setCurrval(allpics.length - 1)
        setSRC(allpics[allpics.length - 1]);

      } else{
        setCurrval(currval - 1)
        setSRC(allpics[currval - 1]);
      }
    }

    function goright() {
      if (currval == allpics.length - 1){
        setCurrval(0)
        setSRC(allpics[0]);

      } else{
        setCurrval(currval + 1)
        setSRC(allpics[currval + 1]);
      }
    }

    const [windowDimenion, detectHW] = useState({
      winWidth: window.innerWidth,
    })
  
    const detectSize = () => {
      detectHW({
        winWidth: window.innerWidth,
      })
    }
  
    useEffect(() => {
      window.addEventListener('resize', detectSize)
      console.log()
  
      return () => {
        window.removeEventListener('resize', detectSize)
      }
    }, [windowDimenion])

    function calcwidth() {
      if (windowDimenion.winWidth >= 992){
        return "22vw"
      } else if (windowDimenion.winWidth >= 770 && windowDimenion.winWidth < 992){
        return "30vw"
      } else{
        return "46vw"
      }
    }

    




    return (
      
    <main style={{padding: '3vw'}}>
          {open ? <Photos goleft = {goleft} goright={goright} srcval={srcval} setpho={setOpen} gol={allpics}  ></Photos> : ""}
        <ResponsiveMasonry
          columnsCountBreakPoints={{0: 2, 770: 3, 992: 4 }}
        >
            <Masonry gutter = "0.2vw">
            <Component photoURL={0} setop={setOpen} setcur={setCurrval} setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={1} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={2} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={3} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={4} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={5} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <img className="image-buffer770"></img>
            <Component photoURL={6} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>            
            <Component photoURL={7} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={8} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <img className="image-buffer770"></img>
            <Component photoURL={9} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={10} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={11} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={12} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={13} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={14} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={15} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={16} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <img className="image-buffer770"></img>
            <Component photoURL={17} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={18} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={19} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={20} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={21} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={22} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <img className="image-buffer770"></img>
            <Component photoURL={23} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={24} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={25} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <img className="image-buffer770"></img>
            <Component photoURL={26} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <img className="image-buffer770"></img>
            <Component photoURL={27} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
            <Component photoURL={28} setop={setOpen} setcur={setCurrval}  setSR={setSRC} width = {calcwidth()}></Component>
          </Masonry> 
        </ResponsiveMasonry>


      </main>
    );
  }


  const Component = ({photoURL, setop, setcur, setSR, width}) => {

    // const { ref, inView } = useInView({
    //   triggerOnce: true,
    // });


    const allpicssmall = [Pic1, Pic2, Pic3, Pic4, Pic5, Pic8, Pic6, Pic7, Pic9, Pic12, Pic10, Pic23, Pic11, Pic13, Pic14, Pic15, Pic16, Pic22, Pic17, Pic19, Pic18, Pic28, Pic20, Pic24, Pic25, Pic21,Pic26, Pic29, Pic27]
    const allpicsmed = [Pic2, Pic1, Pic3, Pic4, Pic5, Pic8, Pic6, Pic7, Pic9, Pic12, Pic10, Pic23, Pic11, Pic13, Pic14, Pic15, Pic16, Pic22, Pic17, Pic19, Pic18, Pic28, Pic20, Pic24, Pic25, Pic21,Pic26, Pic29, Pic27]


    const [currentArr, setcurrentArr] = useState(allpicssmall);

    useEffect(() => {

      if (width <= 770){
        setcurrentArr(allpicssmall)
      }

  
      return () => {

      }
    }, [width])


    

    const allpics = [Pic1, Pic2, Pic3, Pic4, Pic5, Pic8, Pic6, Pic7, Pic9, Pic12, Pic10, Pic23, Pic11, Pic13, Pic14, Pic15, Pic16, Pic22, Pic17, Pic19, Pic18, Pic28, Pic20, Pic24, Pic25, Pic21,Pic26, Pic29, Pic27]

    function update() {
      setop(true);
      setcur(photoURL);
      setSR(allpics[photoURL])
      document.getElementsByTagName("body")[0].classList.add("getstuck");
    }


  
  return(
      <LazyLoadImage  
        // ref={ref} id={inView ? "inv-test" : ""} 
        style ={{cursor: "zoom-in"}} 
        src={currentArr[photoURL]}
        onClick={()=>update()}
        width={width}
        threshold = {900}
        effect ="opacity"
        >
      </LazyLoadImage>
  );

}
  
  
  