import Back from '../photos/contact.jpeg'
 

export default function Contact() {
    
    return (
    <main >
        <div style={{position: "relative"}}>
            <img src={Back} id="contactback" >
            </img>
            <div className='photo-wrapper' style={{position: "absolute", height: "95vh", top: "0px", backgroundColor: "#0000001a"}}>
            </div >
                <div style={{position: "absolute", top: "20vh", left: "4vw"}}>
                <a style ={{lineHeight:"1.7"}}href = "mailto: edwardzavala0@gmail.com"><h2 style={{textDecoration:"underline"}}>edwardzavala0@gmail.com</h2></a>
                <h2>Portrait and Fashion Photographer</h2>
                <h2>Sacramento, CA</h2>
            </div>
        </div>

      </main>

    );
  }