import './App.css';
import instalogo from './insta.svg'
import instlogo from './inst.svg'


import React, { useState } from 'react';



import { Outlet, Link, NavLink} from "react-router-dom";
import Footer from './pages/footer'

export default function App() {

  const [open, setOpen] = useState(false);


  return (
    <div className='site-wrapper' style={open ? {overflow: 'hidden', height: '100vh'}: {}}>
    <div> 
      <nav className='navigation-bar' style={open ? {position: 'sticky', top: '0px'} : {}}>
        <Link to="/"><h1>RT FILMHOUSE</h1></Link>

        <div className='sub-nav desktop'>
        <NavLink
        to="/about"
        onClick={()=>setOpen(false)}
        style={({ isActive }) => 
                      (isActive ? {textDecoration: 'underline'} : null)}
        >     
        About
        </NavLink>    
        <NavLink
        to="/"
        onClick={()=>setOpen(false)}
        style={({ isActive }) => 
                      (isActive ? {textDecoration: 'underline'} : null)}
        >     
        Portfolio
        </NavLink>     
     
        <NavLink
        to="/contact"
        onClick={()=>setOpen(false)}
        style={({ isActive }) => 
                      (isActive ? {textDecoration: 'underline'} : null)}
        >     
        Contact
        </NavLink>  
        <a href= "https://www.instagram.com/rtfilmhouse/" target="_blank"><img id="insta-logo" src={instalogo} alt="React Logo" /></a>
        </div>
        <div id="nav-icon1" className= {open ? "mobile open" : "mobile"} onClick={()=>setOpen(!open)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>
      <div className='mobile-nav mobile-display' id={open ? "open" : "close"}>
        <Link className='mn-text' onClick={()=>setOpen(false)} to="/about" >About</Link>
        <br></br>

        <Link className='mn-text' onClick={()=>setOpen(false)} to="/">Portfolio</Link>
        <br></br>

        <Link className='mn-text' onClick={()=>setOpen(false)} to="/contact">Contact</Link>
        <br></br>
        <a className="insta-mobile-logo" id={open ? "insta-open" : "insta-close"} href= "https://www.instagram.com/rtfilmhouse/" target="_blank"><img  src={instlogo} alt="React Logo" /></a>
      </div>
      <Outlet />
    </div>
    <Footer></Footer>
    </div>
    
  );
}




