import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Overview from './pages/overview';
import Contact from './pages/contact';
import About from './pages/about';
import Error from './pages/error';
import Photos from './pages/photos';
import Portfolio from './pages/portfolio';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
        <Route element={<App />}>
        {/* <Route path ="/" element={<Overview />} /> */}
          <Route path="/" element={<Portfolio />}>
        </Route>
        <Route path ="*" element={<Error />} />
        <Route path="contact" element={<Contact />} />
        <Route path="about" element={<About />} />
        {/* <Route path="/photo" element={<Photos />}>
          <Route path=":photoId" element={<Photo/>} />
        </Route> */}
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
