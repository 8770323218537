import React, { useState, useEffect } from 'react';


import Pic1 from '../photos/desktop-2.JPG'
import Pic2 from '../photos/gallery2.jpeg'
import Pic3 from '../photos/gallery3.jpg'
import Pic4 from '../photos/gallery4.JPG'
import Pic5 from '../photos/gallery5.JPG'
import Pic6 from '../photos/gallery6.JPG'
import Pic7 from '../photos/gallery7.jpg'
import Pic8 from '../photos/gallery8.jpg'
import Pic9 from '../photos/gallery9.JPG'
import Pic10 from '../photos/gallery10.JPG'
import Pic11 from '../photos/desktop-4.JPG'
import Pic12 from '../photos/gallery12.jpg'
import Pic13 from '../photos/gallery13.JPG'
import Pic14 from '../photos/gallery14.JPG'
import Pic15 from '../photos/gallery15.JPG'
import Pic16 from '../photos/desktop-1.gif'
import Pic17 from '../photos/gallery17.JPG'
import Pic18 from '../photos/gallery18.JPG'
import Pic19 from '../photos/desktop-6.gif'
import Pic20 from '../photos/gallery20.JPG'
import Pic21 from '../photos/gallery21.JPG'
import Pic22 from '../photos/gallery22.JPG'
import Pic23 from '../photos/gallery23.jpg'
import Pic24 from '../photos/gallery24.jpg'
import Pic25 from '../photos/gallery25.JPG'
import Pic26 from '../photos/gallery26.JPG'
import Pic28 from '../photos/gallery28.gif'
import Pic27 from '../photos/gallery27.JPG'
import Pic29 from '../photos/gallery29.jpeg'

import lozad from "lozad";
import Macy from "macy";
import BigPicture from "bigpicture";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export default function Portfolio() {

    const allpics = [Pic1, Pic2, Pic3, Pic4, Pic5, Pic8, Pic6, Pic7, Pic9, Pic12, Pic10, Pic23, Pic11, Pic13, Pic14, Pic15, Pic16, Pic22, Pic17, Pic19, Pic18, Pic28, Pic20, Pic24, Pic25, Pic21,Pic26, Pic27, Pic29]
    const ration = [909/614, 3/2, 3/2, 909/614, 2941/1960, 2/3, 3637/2433, 3/2, 2433/3637, 3805/5708, 2433/3637, 909/614, 909/614, 3637/2433, 3637/2433, 909/614, 1757/1378, 2941/1960, 909/614, 1769/1441, 909/614, 1733/1350, 2433/3637, 1228/1818, 3637/2433, 679/1024, 3637/2433, 909/614,909/614]



    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
      })
    
      const detectSize = () => {
        detectHW({
          winWidth: window.innerWidth,
        })
      }
    
      useEffect(() => {
        window.addEventListener('resize', detectSize)
        console.log()
    
        return () => {
          window.removeEventListener('resize', detectSize)
        }
      }, [windowDimenion])
  
      function calcwidth() {
        if (windowDimenion.winWidth >= 992){
          return "23vw"
        } else if (windowDimenion.winWidth >= 770 && windowDimenion.winWidth < 992){
          return "30vw"
        } else{
          return "46vw"
        }
      }
      function calcHeight(index) {
        let var1 = ration[index]
        console.log(var1)	
        if (windowDimenion.winWidth >= 992){
          return String(var1 * 22.8) + "vw"
        } else if (windowDimenion.winWidth >= 770 && windowDimenion.winWidth < 992){
          return String(var1 * 30) + "vw"
        } else{
          return String(var1 * 46) + "vw"
        }
      }

  

    
    function setup() {
        // make masonry grid - macy
        Macy({
          container: "#grid",
          trueOrder: false,
          waitForImages: true,
          margin: 10,
          columns: 3,
          breakAt: {
            1800: 4,
            992: 3,
            770: 2
          }
        });
        // init lazy load - lozad
        // const observer = lozad('.lozad', {
        //     threshold: 0.8 // ratio of element convergence
        // });        
        // observer.observe();
      }

      function launchLightbox(e) {
        // on click - open lightbox - bigpicture
        e.preventDefault();

        BigPicture({
          galleryAttribute: 'src',
          el: e.target,
          gallery: '#grid',
          loop: true,
          animationStart: () => {document.getElementsByTagName("body")[0].classList.add("getstuck");},
        //   animationEnd: () => {document.getElementsByTagName("body")[0].classList.remove("getstuck");},
          onClose: () => {document.getElementsByTagName("body")[0].classList.remove("getstuck");},

          noLoader: true,
        });
      }

      useEffect(() => {
        // Update the document title using the browser API
            setup();
      });
      

    return (
        <main style={{padding: '3vw'}}>
        <div id="grid" class="grid">
             {allpics.map(function(object, i){
             return <div className="grid-item lozad" key={i}>
             <a style={{cursor: "zoom-in"}} target="_blank" data-bp={object} onClick={(e) => launchLightbox(e)}>
                <LazyLoadImage
                width={calcwidth()}
                src={object}
                alt=""
                effect ="opacity"
                height={calcHeight(i)}
                threshold = {"0"}
                >
                </LazyLoadImage>
             {/* <img width={calcwidth()} src={object} alt=""/> */}
             </a>
           </div>;
             })}
        </div>
      </main>
    );
  }