import Pic1 from '../photos/desktop-1.gif'
import Pic2 from '../photos/desktop-2.JPG'
import Pic3 from '../photos/desktop-3.jpg'
import Pic4 from '../photos/desktop-4.JPG'
import Pic5 from '../photos/desktop-5.gif'
import Pic6 from '../photos/desktop-6.gif'






export default function About() {




    return (
      <main >
        <div className="row-var1">
          <div className="text-box">
            <h3 style={{color: "black"}}>For me I feel like I can truly express how I see my world through my photos, snapshots in time of fleeting memories...</h3>
          </div>
          <img className = "about-pic" src={Pic1}></img>
        </div>
        <br></br>
        <div className="row-var2">
          <div className="text-box2">
            <h3 style={{color: "black"}}>...Photos that are simply timeless.</h3>
          </div>
          <img className = "about-pic" src={Pic2}></img>
        </div>
        <br></br>
        <div className="row-var1">
          <div className="text-box">
          <h3 style={{color: "black"}}>Sometimes you will never know the value of a moment until it becomes a memory...</h3>
          </div>
          <img className = "about-pic" src={Pic3}></img>
        </div>
        <br></br>
        <div className="row-var2">
          <div className="text-box2">
            <h3 style={{color: "black"}}>... So allow a great photograph to fully expresses what one feels, in the deepest sense, about what is being photographed.</h3>
          </div>
          <img className = "about-pic" src={Pic4}></img>
        </div>
        <br></br>
        <div className="row-var1">
        <img className = "about-pic" src={Pic5}></img>
          <img className = "about-pic" src={Pic6}></img>
        </div>


        

      </main>
    );
  }